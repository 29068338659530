import React from 'react';

import './Header.css';
import MenuHorizontal from '../UI/Menu/MenuHorizontal';

const Header = (props) => {
    let query = "";
    if(props.location && props.location.search) {
        query = `${props.location.search}`;
    }
    return (
        <div className="Header row">
            <div className="col-md-12 col-sx-12 col-lg-12">
                <MenuHorizontal menu={[
                    // {
                    //     label: "Online Payment",
                    //     url: `/object/paymentMerchant2${query}`
                    // },
                    // {
                    //     label: "GPay Payment",
                    //     url: `/object/GPayOrders${query}`
                    // },
                    // {
                    //     label: "Online Order",
                    //     url: `/object/smsOrder${query}`
                    // },
                    // {
                    //     label: "GrabFood Restaurant",
                    //     url: `/object/GrabFoodRestaurant${query}`
                    // },
                    // {
                    //     label: "GrabFood Orders",
                    //     url: `/object/GrabFoodOrders${query}`
                    // },
                    {
                        label: "Food Panda Restaurant",
                        url: `/object/DeliveryHeroRestaurant${query}`
                    },
                    {
                        label: "Food Panda Orders",
                        url: `/object/DeliveryHeroOrders${query}`
                    },
                ]}
                    type={`/object/paymentMerchant2${query}`}
                ></MenuHorizontal>
            </div>
        </div>
    )
}

export default Header;